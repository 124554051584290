import { MentionType } from "../../state";
import ForceGraph3D from "react-force-graph-3d";
import { Box, Typography } from "@mui/material";
import { GlobalSizes } from "../../size";
import * as THREE from "three";
import { severity } from "../../labels";
import MentionFocus from "../MentionFocus";
import { similarityThreshold } from "../../labels";
import { useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import { useWsContext } from "../../ws-context";
import { useNetworkChart } from "../../useNetworkChart";

function EmbeddingsNetworkChart({
  mentions,
  stringToColor,
  fullScreen,
}: {
  mentions: MentionType[];
  stringToColor: (str: string) => string;
  fullScreen: boolean;
}) {
  const { setSidebarAlert, sidebarAlert } = useWsContext();
  const [imgCache, setImgCache] = useState<{ [key: string]: THREE.Texture }>({});
  const theme = useTheme();
  const { getLinkParticles, getLinkWidth, getLinkParticleWidth } = useNetworkChart();

  const { myData } = useMemo(() => {
    const myData = {
      nodes: [] as any[],
      links: [] as any[],
    };

    for (const mention of mentions) {
      const node = {
        id: mention.url,
        name: mention.description_short || mention.title || mention.url,
        image: mention.avatar,
        source: mention.source,
        val: 1,
      };

      myData.nodes.push(node);

      for (const similarMention of mention.similar || []) {
        if (similarMention.score < similarityThreshold) continue;
        if (mentions.findIndex((m) => m.url === similarMention.url) === -1) continue;
        if ((mention.creation_date || 0) > (similarMention.creation_date || 0)) {
          myData.links.push({
            source: similarMention.url,
            target: mention.url,
            similarity: similarMention.score,
          });
        } else {
          myData.links.push({
            source: mention.url,
            target: similarMention.url,
            similarity: similarMention.score,
          });
        }
      }
    }

    return { myData };
  }, [mentions]);

  const createCircularGlowTexture = () => {
    const size = 256;
    const canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;

    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.beginPath();
      ctx.arc(size / 2, size / 2, size / 3, 0, Math.PI * 2, true);
      ctx.closePath();
      ctx.clip();

      const gradient = ctx.createRadialGradient(size / 2, size / 2, 0, size / 2, size / 2, size / 3);
      gradient.addColorStop(0, "rgba(153, 102, 255, 0.6)");

      // Fill the canvas with the gradient
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, size, size);
    }

    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true; // Mark the texture for update
    return texture;
  };

  const glowTexture = useMemo(createCircularGlowTexture, []);

  const nodeThreeObject = useMemo(() => {
    return (node: any) => {
      const group = new THREE.Group();

      // Add the main node (image or sphere)
      if (imgCache[node.image]) {
        const sprite = new THREE.Sprite(
          new THREE.SpriteMaterial({
            map: imgCache[node.image],
            transparent: true,
            depthWrite: false,
          })
        );
        sprite.scale.set(20, 20, 1);
        group.add(sprite);
      } else {
        const sphere = new THREE.Mesh(new THREE.SphereGeometry(3), new THREE.MeshBasicMaterial({ color: stringToColor(node.source) }));
        group.add(sphere);
      }

      // Add the circular glow only for the sidebar alert node
      if (node.id === sidebarAlert?.url) {
        const glowMaterial = new THREE.SpriteMaterial({
          map: glowTexture, // Use the pre-created circular glow texture
          transparent: true,
          depthWrite: false, // Disable depth writing
        });

        const glowSprite = new THREE.Sprite(glowMaterial);
        glowSprite.scale.set(40, 40, 1); // Slightly larger than the node
        glowSprite.position.set(0, 0, -0.01); // Slightly offset glow behind the node
        group.add(glowSprite);
      }

      return group;
    };
  }, [imgCache, stringToColor, sidebarAlert, glowTexture]);

  useEffect(() => {
    // Preload images and cache them with circular masking
    myData.nodes.forEach((node) => {
      if (node.image && !imgCache[node.image]) {
        const canvas = document.createElement("canvas");
        const size = 64;
        canvas.width = size;
        canvas.height = size;
        const ctx = canvas.getContext("2d");

        if (ctx) {
          const img = new Image();
          img.crossOrigin = "Anonymous";

          img.onload = () => {
            ctx.clearRect(0, 0, size, size);
            ctx.save();
            ctx.beginPath();
            ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2, true);
            ctx.closePath();
            ctx.clip();

            ctx.drawImage(img, 0, 0, size, size);
            ctx.restore();

            const texture = new THREE.Texture(canvas);
            texture.needsUpdate = true;

            setImgCache((prevCache) => ({ ...prevCache, [node.image]: texture }));
          };

          img.src = node.image;
        }
      }
    });
  }, [imgCache, myData.nodes]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom ml={GlobalSizes.gap}>
        AI Narrative Network (Showing {mentions.length} from top 1000 alerts)
      </Typography>
      <ForceGraph3D
        graphData={myData}
        d3AlphaDecay={0.03} // Slows down stabilization
        d3VelocityDecay={0.4} // Reduces jitter
        cooldownTime={2000} // Limits warm-up duration
        cooldownTicks={0}
        width={fullScreen ? window.innerWidth : GlobalSizes.cards.width}
        height={fullScreen ? window.innerHeight - 98 : 500}
        warmupTicks={100}
        enableNodeDrag={false}
        backgroundColor={theme.palette.background.paper}
        nodeColor={(node) => stringToColor(node.name)}
        nodeLabel={(node) => `<div style="color: gray; font-weight: bold;">${node.source} - ${node.name}</div>`}
        linkWidth={getLinkWidth}
        linkDirectionalParticles={getLinkParticles}
        linkDirectionalParticleWidth={getLinkParticleWidth}
        linkOpacity={0.8}
        linkLabel={(link) => `<div style="color: gray; font-weight: bold;">${link.similarity.toFixed(2) * 100 + "% similar"}</div>`}
        linkColor={(link) => {
          if (link.similarity > 0.95) return severity.CRITICAL.color;
          if (link.similarity > 0.9) return severity.HIGH.color;
          if (link.similarity > 0.85) return severity.MEDIUM.color;
          return severity.LOW.color;
        }}
        nodeThreeObject={nodeThreeObject}
        onNodeClick={(node) => {
          setSidebarAlert(mentions.find((m) => m.url === node.id));
        }}
        rendererConfig={{
          powerPreference: "high-performance",
        }}
      />
      <MentionFocus />
    </Box>
  );
}

export default EmbeddingsNetworkChart;
