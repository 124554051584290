import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { GlobalSizes, LoginPageSizes } from "../size";

function LoginPage() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: GlobalSizes.fullScreenHeight,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: GlobalSizes.flex,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          sx={{
            width: GlobalSizes.midSize, // Default width for smaller screens
            height: GlobalSizes.largeHeight,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: GlobalSizes.gap,
            "@media (min-width: 960px)": {
              width: GlobalSizes.halfSize, // 50% width for screens wider than 960px
            },
          }}
        >
          <Typography p={{ pb: GlobalSizes.gap }} variant="h6">
            Welcome to Brinker
          </Typography>
          <img src="/logo900.png" alt="Logo" width={LoginPageSizes.logoSize} height={LoginPageSizes.logoSize} />
          <Divider />
          <Stack direction="row" spacing={GlobalSizes.gap}>
            <Button onClick={() => loginWithRedirect()} variant="outlined" color="primary">
              Log in
            </Button>
            <Typography variant="h6" >
              /
            </Typography>
            <Button
              onClick={() =>
                loginWithRedirect({
                  authorizationParams: {
                    screen_hint: "signup",
                  },
                })
              }
              variant="contained"
              color="primary"
            >
              Sign up
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export default LoginPage;
