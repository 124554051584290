import { Dialog, DialogActions, DialogContent, TextField, Button, Tooltip, Box, Typography, Badge, CircularProgress } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { GlobalSizes } from "../../size";
import { useState } from "react";
import { useWsContext } from "../../ws-context";
import { labels } from "../../labels";

interface ImportDialogProps {
  isImportDialogOpen: boolean;
  setIsImportDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ImportDialog({ isImportDialogOpen, setIsImportDialogOpen }: ImportDialogProps) {
  const [flagInput, setFlagInput] = useState("");
  const [isUploadingCsv, setIsUploadingCsv] = useState({
    name: "",
    status: "",
  });

  const { emitNewData, dashboardState, updateAlerts, socketRef } = useWsContext();

  const handleAddLink = async () => {
    if (!flagInput) return;
    const mention = {
      url: flagInput.trim(),
      flagged: true,
      collected_manually: true,
      detection_date: Date.now(),
      flag_date: Date.now(),
      customer_id: dashboardState.customer_id,
    };
    updateAlerts(mention);
    emitNewData({ action: "updateAlert", mention });
    setIsImportDialogOpen(false);
    setFlagInput("");
  };

  const handleUploadCsv = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !file.type.endsWith("csv")) {
      console.error("No appropriate file selected");
      event.target.value = "";
      return;
    }

    setIsUploadingCsv({ name: file.name, status: "loading" });
    const reader = new FileReader();
    reader.onload = async (e) => {
      const csv = e.target?.result;
      if (!csv) return;
      emitNewData({ action: "uploadCsv", csv });
      const handleWebSocketMessage = (event: MessageEvent) => {
        const { data } = JSON.parse(event.data);
        if (data?.csv === "completed") {
          setIsUploadingCsv({ name: file.name, status: "completed" });
        }
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      };
      event.target.value = "";
      socketRef.current?.addEventListener("message", handleWebSocketMessage);
    };
    reader.readAsText(file);
  };

  return (
    <Dialog open={isImportDialogOpen} onClose={() => setIsImportDialogOpen(false)} PaperProps={{ sx: { width: GlobalSizes.smallSize } }}>
      <DialogContent>
        <Typography mb={GlobalSizes.smallGap} variant="h6">
          Add URL to flag or upload alerts via CSV
        </Typography>
        <TextField label="Enter URL to flag" fullWidth variant="outlined" value={flagInput} onChange={(e) => setFlagInput(e.target.value)} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Tooltip
          title={
            {
              loading: `Uploading ${isUploadingCsv.name}...`,
              completed: `${isUploadingCsv.name} uploaded successfully!`,
              error: `Failed to upload ${isUploadingCsv.name}, please try again!`,
              processing: labels.monitoring.csv,
            }[isUploadingCsv.status] || "Upload alerts via CSV"
          }
        >
          <Button
            size="small"
            component="label"
            startIcon={
              <Badge
                badgeContent={
                  {
                    completed: <DoneOutlinedIcon fontSize="inherit" />,
                    error: <CloseOutlinedIcon fontSize="inherit" />,
                  }[isUploadingCsv.status] || null
                }
              >
                {{ loading: <CircularProgress size={GlobalSizes.smallFontSize} />, processing: <CircularProgress size={GlobalSizes.smallFontSize} /> }[
                  isUploadingCsv.status
                ] || <FileUploadOutlinedIcon fontSize="small" />}
              </Badge>
            }
          >
            <input type="file" hidden accept=".csv" onChange={handleUploadCsv} />
            Import
          </Button>
        </Tooltip>
        <Box>
          <Button onClick={() => setIsImportDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddLink} color="primary">
            Add
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default ImportDialog;
