import { AssetType } from "../../state";
import { unixToLocaleDate } from "../../labels";
import { IconButton, Tooltip, Menu, MenuItem, Box, FormControlLabel, Switch, ListItemIcon } from "@mui/material";
import { useWsContext } from "../../ws-context";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import React, { useState, useMemo } from "react";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TagIcon from "@mui/icons-material/Tag";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { SOCIAL_INFO } from "../../labels";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GlobalSizes } from "../../size";
import AlertsCounter from "../AlertsCounter";
import DeleteIcon from "@mui/icons-material/Delete"

function AssetCard({ asset, value, deleteAsset }: { asset: string; value: AssetType; deleteAsset: (asset: string) => void }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { mentionsState, dashboardState, updateDashboard, emitNewData } = useWsContext();

  const isMenuOpen = Boolean(anchorEl);

  const { type, ...restValues } = value;

  const relevantAlerts = useMemo(
    () => mentionsState.filter((mention) => mention.asset === asset).sort((a, b) => (b.creation_date || 0) - (a.creation_date || 0)),
    [asset, mentionsState]
  );

  const formatValue = (key: string, value: (string | number | boolean) | { [key: string]: number }) => {
    if (typeof value === "boolean") return "";
    if (typeof value === "object") {
      return Object.entries(value).map(([childKey, value]) => {
        return (
          <div key={`${key}-${childKey}`}>
            {key} {childKey}: {unixToLocaleDate(value)}
          </div>
        );
      });
    }
    if (key.includes("date")) {
      return (
        <div>
          {key}: {unixToLocaleDate(value as number)}
        </div>
      );
    }
    return <div>value</div>;
  };

  const moreInfo = Object.entries(restValues).map(([key, value]) => {
    return <span key={key}>{formatValue(key, value)}</span>;
  });

  const handleAssetActivity = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    updateDashboard({
      assets: { ...dashboardState.assets, [asset]: { ...value, disabled: !checked } },
    });
    emitNewData({
      action: "setAsset",
      asset: {
        [asset]: { ...value, disabled: !checked },
      },
    });
  };

  const textColor = value.disabled ? "text.disabled" : "text.primary";

  return (
    <ListItem
      sx={{ my: GlobalSizes.gap }}
      divider
      secondaryAction={
        <IconButton id="more-button" onClick={(event) => setAnchorEl(event.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
      }
    >
      <ListItemAvatar sx={{ color: textColor }}>{searchType(asset).icon}</ListItemAvatar>
      <Tooltip title={moreInfo}>
        <ListItemText
          primaryTypographyProps={{
            color: textColor,
          }}
          secondaryTypographyProps={{
            color: textColor,
          }}
          primary={searchType(asset).format(asset)}
          secondary={<React.Fragment>{searchType(asset).label}</React.Fragment>}
        />
      </Tooltip>
      <Box sx={{ mr: GlobalSizes.gap }}>
        <AlertsCounter alerts={relevantAlerts} />
      </Box>
      <Menu id="menu" anchorEl={anchorEl} open={isMenuOpen} onClose={() => setAnchorEl(null)}>
        <MenuItem key="switch">
          <FormControlLabel
            control={<Switch size="small" checked={!value.disabled} onChange={handleAssetActivity} />}
            label={value.disabled ? "Disabled" : "Enabled"}
          />
        </MenuItem>
        <MenuItem key="delete" onClick={() => deleteAsset(asset)}>
          <Tooltip title={`Delete ${asset} (does not delete alerts)`}>
            <ListItemIcon sx={{gap: GlobalSizes.gap, color: 'inherit'}}>
              <DeleteIcon fontSize="small" />
              Delete
            </ListItemIcon>
          </Tooltip>
        </MenuItem>
      </Menu>
    </ListItem>
  );
}

const searchType = (asset: string | null) => {
  const defaultType = (overrides: Partial<{ label: string; format: (asset: string) => string; icon: JSX.Element | null }>) => ({
    label: "Monitor this item",
    format: (asset: string) => asset,
    icon: <GpsFixedIcon />,
    ...overrides,
  });

  if (!asset) return defaultType({ label: 'Enter term, user, #hashtag or "exact phrase" to monitor', icon: null });

  if (asset.includes("https://")) {
    try {
      const url = new URL(asset);
      const domain = url.hostname.replace("www.", "");
      const SelectedIcon = SOCIAL_INFO[domain]?.icon;
      const isGroup = url.pathname.split("/")[1] === "groups";
      return defaultType({
        label: !!SelectedIcon ? (isGroup ? "Monitor this group" : "Monitor this user") : "Monitor this URL",
        icon: SelectedIcon ? <SelectedIcon /> : <GpsFixedIcon />,
      });
    } catch (error) {
      return defaultType({ label: "Monitor this item", icon: <GpsFixedIcon /> });
    }
  }

  if (asset.startsWith("#")) return defaultType({ label: "Monitor this #hashtag", icon: <TagIcon /> });
  if (asset.startsWith("@")) return defaultType({ label: "Monitor this @userName", icon: <AccountCircleIcon /> });
  if (asset.startsWith('"') && asset.endsWith('"')) {
    return defaultType({ label: 'Monitor this "exact phrase"', icon: <FormatQuoteIcon /> });
  }

  if (asset.includes(" ")) {
    return defaultType({
      label: "Monitor items that contain ALL of these words",
      icon: <SpeakerNotesIcon />,
      format: (asset: string) => asset.replaceAll(" ", " ➕ "),
    });
  }

  return defaultType({});
};

export { AssetCard, searchType };
