import { useState } from "react";
import { Box, TextField } from "@mui/material";
import { GlobalSizes } from "../size";
import { MentionType } from "../state";
import { useWsContext } from "../ws-context";

export default function Note({ mention }: { mention?: MentionType }) {
  const [label, setLabel] = useState("Note");

  const { updateAlerts, emitNewData } = useWsContext();
  if (!mention) return null;
  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const newNote = e.target.value;
    if (mention.note === newNote) return;
    const { customer_id, url } = mention;
    emitNewData({ action: "updateAlert", mention: { customer_id, url, note: newNote } });
    setLabel("Note (Saved)");
    updateAlerts({ ...mention, note: newNote });
  };

  return (
    <Box mt={GlobalSizes.gap}>
      <TextField id="filled-multiline-static" label={label} multiline rows={5} fullWidth defaultValue={mention.note} variant="filled" onBlur={handleBlur} />
    </Box>
  );
}
