import { MentionType } from "../../state";
import ForceGraph2D from "react-force-graph-2d";
import { Box, Typography } from "@mui/material";
import { GlobalSizes } from "../../size";
import { severity } from "../../labels";
import MentionFocus from "../MentionFocus";
import { useEffect, useMemo, useState } from "react";
import { similarityThreshold } from "../../labels";
import { useTheme } from "@mui/material";
import { useWsContext } from "../../ws-context";
import { useNetworkChart } from "../../useNetworkChart";

function EmbeddingsNetworkChart2D({
  mentions,
  stringToColor,
  fullScreen,
}: {
  mentions: MentionType[];
  stringToColor: (str: string) => string;
  fullScreen: boolean;
}) {
  const { setSidebarAlert, sidebarAlert } = useWsContext();
  const [imgCache, setImgCache] = useState<{ [key: string]: HTMLImageElement }>({});
  const theme = useTheme();
  const { getLinkParticles, getLinkWidth, getLinkParticleWidth } = useNetworkChart();

  const { myData } = useMemo(() => {
    const myData = {
      nodes: [] as any[],
      links: [] as any[],
    };

    for (const mention of mentions) {
      const node = {
        id: mention.url,
        name: mention.description_short || mention.title || mention.url,
        image: mention.avatar,
        source: mention.source,
        val: 1,
      };

      myData.nodes.push(node);

      for (const similarMention of mention.similar || []) {
        if (similarMention.score < similarityThreshold) continue;
        if (mentions.findIndex((m) => m.url === similarMention.url) === -1) continue;
        if ((mention.creation_date || 0) > (similarMention.creation_date || 0)) {
          myData.links.push({
            source: similarMention.url,
            target: mention.url,
            similarity: similarMention.score,
          });
        } else {
          myData.links.push({
            source: mention.url,
            target: similarMention.url,
            similarity: similarMention.score,
          });
        }
      }
    }

    return { myData };
  }, [mentions]);

  const nodeCanvasObject = useMemo(() => {
    return (node: any, ctx: CanvasRenderingContext2D, globalScale: number) => {
      const isSelected = sidebarAlert?.url === node.id;
      const radius = 4 / globalScale;

      ctx.beginPath();
      ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI, false);
      ctx.fillStyle = stringToColor(node.source);
      ctx.fill();

      if (isSelected) {
        ctx.beginPath();
        ctx.arc(node.x, node.y, radius * 4, 0, 2 * Math.PI, false);
        ctx.fillStyle = "rgba(153, 102, 255, 0.6)";
        ctx.fill();
      }

      const img = imgCache[node.image];
      if (img) {
        const size = radius * 4;
        ctx.save();
        ctx.beginPath();
        ctx.arc(node.x, node.y, size / 2, 0, 2 * Math.PI, false);
        ctx.clip();
        ctx.drawImage(img, node.x - size / 2, node.y - size / 2, size, size);
        ctx.restore();
      }
    };
  }, [imgCache, stringToColor, sidebarAlert]);

  useEffect(() => {
    myData.nodes.forEach((node) => {
      if (node.image && !imgCache[node.image]) {
        const img = new Image();
        img.src = node.image;
        img.onload = () => setImgCache((prevCache) => ({ ...prevCache, [node.image]: img }));
      }
    });
  }, [imgCache, myData.nodes]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom ml={GlobalSizes.gap}>
        AI Narrative Network (Showing {mentions.length} from top 1000 alerts)
      </Typography>
      <ForceGraph2D
        graphData={myData}
        width={fullScreen ? window.innerWidth : GlobalSizes.cards.width}
        height={fullScreen ? window.innerHeight - 98 : 500}
        backgroundColor={theme.palette.background.paper}
        warmupTicks={100}
        cooldownTime={2000} // Limits warm-up duration
        nodeRelSize={4}
        nodeColor={(node) => stringToColor(node.name)}
        nodeLabel={(node) => `${node.source ? node.source : "Manually collected"} - ${node.name}`}
        linkWidth={getLinkWidth}
        linkLabel={(link) => `${(link.similarity * 100).toFixed(2)}% similar`}
        linkDirectionalParticles={getLinkParticles}
        linkDirectionalParticleWidth={getLinkParticleWidth}
        linkColor={(link) => {
          if (link.similarity > 0.95) return severity.CRITICAL.color;
          if (link.similarity > 0.9) return severity.HIGH.color;
          if (link.similarity > 0.85) return severity.MEDIUM.color;
          return severity.LOW.color;
        }}
        onNodeClick={(node) => setSidebarAlert(mentions.find((m) => m.url === node.id))}
        onNodeDragEnd={(node) => {
          node.fx = node.x;
          node.fy = node.y;
        }}
        nodeCanvasObject={nodeCanvasObject}
      />
      <MentionFocus />
    </Box>
  );
}

export default EmbeddingsNetworkChart2D;
