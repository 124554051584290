import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { labels, severity, similarityThreshold, SOCIAL_INFO, unixToLocaleDate } from "./labels";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Badge, Box, Chip, Tooltip } from "@mui/material";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import { useWsContext } from "./ws-context";
import { Link } from "react-router-dom";
import SeverityChip from "./Components/SeverityChip";
import { AiFilterType, AiResultType, MentionType } from "./state";
import { useClientContext } from "./client-context";
import { GlobalSizes } from "./size";
import RefreshAiFilter from "./Components/investigation/ai-filter/RefreshAiFilter";
import AssetHighlighter from "./Components/assets/AssetHighlighter";

function parseNumberWithUnits(str: string) {
  const units = { k: 1e3, m: 1e6, b: 1e9, t: 1e12 };
  const regex = /^(\d+(\.\d+)?)([kmbt]?)$/i;
  const match = str?.match(regex);

  if (!match) return null;

  const [, number, , unit] = match;
  const multiplier = unit ? units[unit.toLowerCase() as keyof typeof units] : 1;
  return parseFloat(number) * multiplier;
}

export const useColumns = (isListView?: boolean) => {
  const { mentionsState } = useWsContext();
  const { stringToColor } = useClientContext();

  const valueOptions = useMemo(() => {
    const fields = ["source", "alertType", "type", "asset"] as const;

    const options: Record<string, Record<string, number>> = fields.reduce((acc, field) => {
      acc[field] = {};
      return acc;
    }, {} as Record<string, Record<string, number>>);

    // Single loop through mentionsState
    mentionsState.forEach((mention) => {
      fields.forEach((field) => {
        const value = mention[field];
        if (value) {
          options[field][value] = (options[field][value] || 0) + 1;
        }
      });
    });

    // Convert options to sorted arrays with value-label objects
    return fields.reduce((acc, field) => {
      acc[field] = Object.entries(options[field])
        .sort((a, b) => b[1] - a[1])
        .map((tuple) => ({ value: tuple[0], label: `${tuple[0]} (${tuple[1]})` }));
      return acc;
    }, {} as Record<string, { value: string; label: string }[]>);
  }, [mentionsState]);

  const aiFilterValueGetter = useCallback((cell: AiFilterType) => {
    if (!cell) return "0000003";
    if (!cell.results){
      const status = cell.status;
      if (status === "nothing_found") return `0000004 ${status}`;
      if (status === "processing") return `0000005 ${status}`;
      if (status === "failed") return `0000001 ${status}`;
      if (status === "skipped") return `0000002 ${status}`;
    }
    const trueAnswers = cell.results?.filter((q: AiResultType) => {
      return q.content?.booleanAnswer;
    });
    return trueAnswers?.length.toString().padStart(5, "0") + " " + trueAnswers?.map((q) => q.key?.replaceAll("_", " ")).join(", ");
  }, []);

  const aiFilterRenderCell = useCallback(
    (aiFilter: AiFilterType, params: GridRenderCellParams) => {
      if (!aiFilter) return null;
      const fromSimilarFilter = aiFilter.reason === "Inherited";
      if (!aiFilter.results) {
        return (
          <Tooltip title={aiFilter.reason}>
            <Chip size="small" label={aiFilter.status} />
          </Tooltip>
        );
      }

      const tableTrueQuestions = aiFilter.results?.map((value: AiResultType, index: number) => (
        <Tooltip title={value.content?.explanation} key={index}>
          <Chip size="small" label={value.key} sx={{ backgroundColor: stringToColor(value.key), color: "black", marginLeft: GlobalSizes.smallGap }} />
        </Tooltip>
      ));

      const listTrueQuestions = aiFilter.results?.map((value: AiResultType, index: number) => (
        <Tooltip title={value.key} key={index}>
          <Box
            sx={{
              width: 25,
              height: 5,
              borderRadius: "25%",
              backgroundColor: stringToColor(value.key),
              display: "inline-block",
              marginLeft: GlobalSizes.smallGap,
            }}
          />
        </Tooltip>
      ));

      return (
        <div>
          <Tooltip key={params.row.url} title={labels.ai.success_count(tableTrueQuestions.length) + (fromSimilarFilter ? " (from similar alert)" : "")}>
            <Chip
              size="small"
              icon={fromSimilarFilter ? <FindReplaceIcon /> : <TroubleshootIcon />}
              label={tableTrueQuestions.length}
              sx={{ marginRight: GlobalSizes.smallGap }}
            />
          </Tooltip>
          {isListView ? listTrueQuestions : tableTrueQuestions}
        </div>
      );
    },
    [isListView, stringToColor]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "avatar",
        headerName: "👤",
        width: 60,
        renderCell: (params: GridRenderCellParams) => {
          const SelectedIcon = SOCIAL_INFO[params.row.source]?.icon || PersonIcon;
          return (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={<SelectedIcon color="disabled" fontSize="small" />}
            >
              <Avatar alt={params.row.user} src={params.value} />
            </Badge>
          );
        },
      },
      {
        field: "user",
        headerName: "User",
        width: 150,
        type: "string" as const,
      },
      {
        field: "source",
        headerName: "Source",
        width: 120,
        type: "singleSelect" as const,
        valueOptions: valueOptions.source,
        valueFormatter: (value: string) => value,
      },
      {
        field: "detection_date",
        headerName: "Detected",
        type: "date" as const,
        valueGetter: (value: Date) => {
          return value ? new Date(value) : 0;
        },
        renderCell: (params) => {
          if (!params.value) return null;
          return (
            <Tooltip title={unixToLocaleDate(params.value)}>
              <span>{params.value.toLocaleDateString(undefined, { month: "short", day: "numeric" })}</span>
            </Tooltip>
          );
        },
      },
      {
        field: "creation_date",
        headerName: "Created",
        minWidth: 90,
        type: "date" as const,
        valueGetter: (value: Date) => {
          return value ? new Date(value) : 0;
        },
        renderCell: (params) => {
          if (!params.value) return null;
          return (
            <Tooltip title={unixToLocaleDate(params.value)}>
              <span>{params.value.toLocaleDateString()}</span>
            </Tooltip>
          );
        },
      },
      {
        field: "description_short",
        headerName: "Content",
        width: 300,
        type: "string" as const,
        renderCell: (params: GridRenderCellParams) => {
          const content = params.value;
          const asset = params.row.asset;
          if (!content || !asset) return;
          return (
            <span title={content}>
              <AssetHighlighter text={content} asset={asset} />
            </span>
          );
        },
      },
      {
        field: "url",
        headerName: "Link",
        width: 300,
        maxWidth: 600,
        type: "string" as const,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Link to={params.row.url} target="_blank" rel="noopener noreferrer">
              {params.row.url}
            </Link>
          );
        },
      },
      {
        field: "note",
        headerName: "Notes",
        type: "string" as const,
      },
      {
        field: "impressions",
        headerName: "Impressions",
        type: "number" as const,
        valueGetter: (value: number | string) => {
          if (typeof value === "string") {
            return parseNumberWithUnits(value);
          }
          return value;
        },
      },
      {
        field: "alertType",
        headerName: "Risk",
        type: "singleSelect" as const,
        valueOptions: valueOptions.alertType,
        valueFormatter: (value: string) => value,
      },
      {
        field: "type",
        headerName: "Item",
        type: "singleSelect" as const,
        valueOptions: valueOptions.type,
        valueFormatter: (value: string) => value,
      },
      {
        field: "repost",
        headerName: "Repost",
        type: "string" as const,
      },
      {
        field: "severity",
        headerName: "Severity",
        valueGetter: (value: string, params: MentionType) => {
          const finalValue = params?.manual_severity || value || "medium";
          return severity[finalValue.toUpperCase()].value;
        },
        valueOptions: [
          { value: 4, label: "Critical" },
          { value: 3, label: "High" },
          { value: 2, label: "Medium" },
          { value: 1, label: "Low" },
        ],
        renderCell: (params: GridRenderCellParams) => {
          return <SeverityChip alert={params.row} />;
        },
      },
      {
        field: "similar",
        headerName: "Similarity",
        width: 120,
        type: "number" as const,
        valueGetter: (value: { score: number }[]) => {
          const score = value?.[0]?.score;
          return score && score >= similarityThreshold ? score * 100 : null;
        },
        renderCell: (params: GridRenderCellParams) => {
          if (params.value === null) return null;
          return (
            <div>
              <Tooltip key={params.row.url} title="Click row to see similar alerts">
                <Chip label={params.value.toFixed(2) + "%"} variant="outlined" />
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "asset",
        headerName: "Asset",
        type: "singleSelect" as const,
        valueOptions: valueOptions.asset,
        valueFormatter: (value: string) => value,
      },
      {
        field: "location",
        headerName: "Location",
        type: "string" as const,
      },
      {
        field: "language",
        headerName: "Language",
        type: "string" as const,
      },
      {
        field: "takedown_status",
        headerName: "Takedown Status",
        type: "string" as const,
      },
      {
        field: "ai_filter",
        headerName: labels.ai.title,
        type: "string" as const,
        valueGetter: (cell: AiFilterType) => {
          return aiFilterValueGetter(cell);
        },
        renderCell: (params: GridRenderCellParams) => {
          return aiFilterRenderCell(params.row.ai_filter, params);
        },
      },
      {
        field: "ai_filter_beta",
        headerName: labels.ai.title_beta,
        valueGetter: (cell: AiFilterType, row: MentionType) => {
          return aiFilterValueGetter(cell);
        },
        renderCell: (params: GridRenderCellParams) => {
          return aiFilterRenderCell(params.row.ai_filter_beta, params);
        },
      },
      {
        field: "refresh_ai_filter",
        headerName: `Refresh ${labels.ai.title}`,
        renderCell: (params: GridRenderCellParams) => {
          return <RefreshAiFilter alert={params.row} />;
        },
      },
      {
        field: "status",
        headerName: "Status",
        type: "singleSelect" as const,
        valueGetter: (value: undefined, row: MentionType) => {
          if (row.hidden) return "hidden";
          if (row.flagged) return "flagged";
          if (row.whitelisted) return "whitelisted";
        },
        valueOptions: [
          { value: "hidden", label: "archived" },
          { value: "flagged", label: "flagged" },
          { value: "whitelisted", label: "whitelisted" },
        ],
      },
    ],
    [aiFilterRenderCell, aiFilterValueGetter, valueOptions]
  );

  const listViewColumns = useMemo(() => columns.filter((col) => col.field !== "status"), [columns]);

  return { columns, listViewColumns, aiFilterRenderCell };
};
