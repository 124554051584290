import { useMemo } from "react";
import { similarityThreshold } from "../labels";
import { MentionType } from "../state";
import InquiriesPieChart from "./charts/InquiriesPieChart";
import InquiriesLineChart from "./charts/InquiriesLineChart";
import EmbeddingsScatterChart from "./charts/EmbeddingsScatterChart";
import EmbeddingsNetworkChart from "./charts/EmbeddingsNetworkChart";
import EmbeddingsNetworkChart2D from "./charts/EmbeddingsNetworkChart2D";
import DirectedAcyclicGraph from "./charts/DirectedAcyclicGraph";
import { useState } from "react";
import { Box, ToggleButtonGroup, ToggleButton, Button } from "@mui/material";
import { useClientContext } from "../client-context";

function InquiriesCharts({
  mentions,
  setShowFullScreenCharts,
  showFullScreenCharts = false,
}: {
  mentions: MentionType[];
  setShowFullScreenCharts: React.Dispatch<React.SetStateAction<boolean>>;
  showFullScreenCharts?: boolean;
}) {
  const [networkView, setNetworkView] = useState<"3D" | "2D-Network" | "2D-Acyclic">("3D");
  const { stringToColor } = useClientContext();

  const alertsWithAiFilter = useMemo(() => mentions.filter((m) => m.ai_filter?.results?.length), [mentions]);

  const reducedEmbeddingsAlerts = useMemo(() => mentions.filter((m) => m.reduced_embedding), [mentions]);

  const lineChartAlerts = useMemo(
    () => alertsWithAiFilter.filter((m) => m.creation_date && new Date(m.creation_date) >= new Date(new Date().setMonth(new Date().getMonth() - 3))),
    [alertsWithAiFilter]
  );

  const mentionsWithSimilarity = useMemo(() => mentions.filter((m) => (m.similar?.[0]?.score || 0) > similarityThreshold), [mentions]);
  const memoizedTopMentionsWithSimilarity = useMemo(() => mentionsWithSimilarity.slice(0, 1000), [mentionsWithSimilarity]);

  const embeddingsNetworkCharts = () => {
    return (
      <Box>
        <ToggleButtonGroup
          size="small"
          value={networkView}
          exclusive
          onChange={(event: React.MouseEvent<HTMLElement>, newView: "3D" | "2D-Network" | "2D-Acyclic") => {
            console.log({ event, newView });
            if (newView) {
              setNetworkView(newView);
            }
          }}
          aria-label="view type"
          sx={showFullScreenCharts ? { margin: "10px" } : {}}
        >
          <ToggleButton value="3D" aria-label="3D Network">
            Network (3D)
          </ToggleButton>
          <ToggleButton value="2D-Network" aria-label="2D Network">
            Network (2D)
          </ToggleButton>
          <ToggleButton value="2D-Acyclic" aria-label="2D Acyclic">
            Acyclic
          </ToggleButton>
        </ToggleButtonGroup>
        {!showFullScreenCharts && (
          <Button onClick={() => setShowFullScreenCharts((prev) => !prev)} sx={{ marginLeft: "10px" }}>
            Full Screen
          </Button>
        )}
        {networkView === "2D-Network" && (
          <EmbeddingsNetworkChart2D mentions={memoizedTopMentionsWithSimilarity} stringToColor={stringToColor} fullScreen={showFullScreenCharts} />
        )}
        {networkView === "3D" && (
          <EmbeddingsNetworkChart mentions={memoizedTopMentionsWithSimilarity} stringToColor={stringToColor} fullScreen={showFullScreenCharts} />
        )}
        {networkView === "2D-Acyclic" && (
          <DirectedAcyclicGraph mentions={memoizedTopMentionsWithSimilarity} stringToColor={stringToColor} fullScreen={showFullScreenCharts} />
        )}
      </Box>
    );
  };

  if (!showFullScreenCharts) {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        {alertsWithAiFilter.length > 0 && <InquiriesPieChart mentions={alertsWithAiFilter} stringToColor={stringToColor} />}
        {lineChartAlerts.length > 0 && <InquiriesLineChart mentions={lineChartAlerts} stringToColor={stringToColor} />}
        {mentionsWithSimilarity.length >= 2 && <Box>{embeddingsNetworkCharts()}</Box>}
        {reducedEmbeddingsAlerts.length >= 10 && <EmbeddingsScatterChart mentions={reducedEmbeddingsAlerts} stringToColor={stringToColor} />}
      </Box>
    );
  }
  return <Box>{mentionsWithSimilarity.length >= 2 && <Box>{embeddingsNetworkCharts()}</Box>}</Box>;
}

export default InquiriesCharts;
