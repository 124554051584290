import { Box } from "@mui/material";
import { GlobalSizes } from "../../size";
import { gridFilteredSortedRowEntriesSelector, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useColumns } from "../../useColumns";
import { MentionType } from "../../state";
import { useClientContext } from "../../client-context";
import { useWsContext } from "../../ws-context";
import { useCallback, useEffect } from "react";

function HomeFilters({
  filteredMentions,
  setFilteredMentions,
}: {
  filteredMentions: MentionType[];
  setFilteredMentions: React.Dispatch<React.SetStateAction<MentionType[]>>;
}) {
  const { mentionsState } = useWsContext();
  const { homeFilterModel, setHomeFilterModel } = useClientContext();
  const { columns } = useColumns();
  const apiRef = useGridApiRef();

  const applyFilteredMentions = useCallback(() => {
    if (apiRef.current) {
      setFilteredMentions(gridFilteredSortedRowEntriesSelector(apiRef).map((m) => m.model) as MentionType[]);
    }
  }, [apiRef, setFilteredMentions]);

  useEffect(() => {
    if (!filteredMentions.length) {
      applyFilteredMentions();
    }
  }, [mentionsState, filteredMentions.length, applyFilteredMentions]);

  return (
    <Box width="112px" height="50px">
      <DataGridPro
        slots={{
          toolbar: () => (
            <GridToolbarContainer sx={{ p: GlobalSizes.smallGap }}>
              <GridToolbarFilterButton />
            </GridToolbarContainer>
          ),
        }}
        filterModel={homeFilterModel}
        onFilterModelChange={(model) => {
          setHomeFilterModel({
            items: model.items,
            logicOperator: model.logicOperator,
          });
        }}
        onPreferencePanelClose={applyFilteredMentions}
        rows={mentionsState}
        getRowId={(row) => row.url}
        columns={columns}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            display: "none",
          },
        }}
        apiRef={apiRef}
        hideFooter
        pagination
        pageSizeOptions={[0]}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 0 },
          },
        }}
      />
    </Box>
  );
}

export default HomeFilters;
