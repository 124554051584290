import { MentionType } from "../../state";
import ForceGraph2D, { ForceGraphMethods } from "react-force-graph-2d";
import { Box, Typography, Tooltip } from "@mui/material";
import { GlobalSizes } from "../../size";
import { TYPES_ICONS, severity, SOCIAL_INFO } from "../../labels";
import MentionFocus from "../MentionFocus";
import { useEffect, useMemo, useRef, useState } from "react";
import { similarityThreshold } from "../../labels";
import { renderToString } from "react-dom/server";
import { useWsContext } from "../../ws-context";
import { useTheme } from "@mui/material";
import { useNetworkChart } from "../../useNetworkChart";

function DirectedAcyclicGraph({
  mentions,
  stringToColor,
  fullScreen,
}: {
  mentions: MentionType[];
  stringToColor: (str: string) => string;
  fullScreen: boolean;
}) {
  const [imgCache, setImgCache] = useState<{ [key: string]: HTMLImageElement }>({});
  const { setSidebarAlert, sidebarAlert } = useWsContext();
  const [selectedUserNode, setSelectedUserNode] = useState<string | null>(null);
  const graphRef = useRef<ForceGraphMethods<any, any> | undefined>(undefined);
  const [isZoomedOut, setIsZoomedOut] = useState(false);
  const theme = useTheme();
  const nodeFillColor = theme.palette.mode === "light" ? "#4f4f4f" : "#c0c0c0";
  const linkFillColor = theme.palette.mode === "light" ? "#c0c0c0" : "#4f4f4f";
  const { getLinkParticles, getLinkWidth, getLinkParticleWidth } = useNetworkChart();

  const preRenderedIcons = useMemo(() => {
    const renderIcon = (IconComponent: React.ElementType) =>
      renderToString(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={nodeFillColor}>
          <IconComponent />
        </svg>
      );

    const svgToDataUrl = (svg: string) => {
      const blob = new Blob([svg], { type: "image/svg+xml" });
      return URL.createObjectURL(blob);
    };

    const allIcons = { ...Object.fromEntries(Object.entries(SOCIAL_INFO).map(([key, value]) => [key, value.icon])), ...TYPES_ICONS };
    const preRendered: Record<string, string> = {};

    for (const [key, IconComponent] of Object.entries(allIcons)) {
      const svg = renderIcon(IconComponent);
      preRendered[key] = svgToDataUrl(svg);
    }

    return preRendered;
  }, [nodeFillColor]);

  // Clear the image cache when the theme changes
  useEffect(() => {
    setImgCache({});
  }, [theme.palette.mode]);

  const mentionsWithAssetAndNarrative = useMemo(() => {
    return mentions.filter((mention) => mention.asset && mention.ai_filter?.results?.length);
  }, [mentions]);

  const sortedMentionsWithAssetAndNarrative = useMemo(() => {
    return [
      ...mentionsWithAssetAndNarrative.filter((mention) => mention.type === "profile"), // Profiles first
      ...mentionsWithAssetAndNarrative.filter((mention) => mention.type !== "profile"),
    ];
  }, [mentionsWithAssetAndNarrative]);

  const { myData } = useMemo(() => {
    const myData = {
      nodes: [] as any[],
      links: [] as any[],
    };

    const narrativeNodes = new Set<string>();

    // Add root node
    myData.nodes.push({
      id: "Root",
    });

    const getMentionImage = (mention: MentionType) => {
      if (!mention.type) return preRenderedIcons["post"];
      if (mention.type === "profile") return mention.avatar || preRenderedIcons["profile"];
      if (mention.type !== "article") return preRenderedIcons[mention.type];
      return mention.avatar;
    };

    for (const mention of sortedMentionsWithAssetAndNarrative) {
      // Create mention node
      const mentionNode = {
        id: mention.url,
        name: mention.description_short || mention.title || mention.url,
        image: getMentionImage(mention),
        source: mention.source,
        user: mention.user_id || `${mention.user}-${mention.source}`,
        type: mention.type,
        label: mention.user,
      };
      myData.nodes.push(mentionNode);

      let platformNodeId: string | undefined;
      let userNodeId: string;

      if (mention.type !== "article") {
        // Add source node
        if (mention.source) {
          platformNodeId = `source-${mention.source}`;
          if (!myData.nodes.find((node) => node.id === platformNodeId)) {
            myData.nodes.push({
              id: platformNodeId,
              name: mention.source,
              image: preRenderedIcons[mention.source],
              source: "Source",
            });
          }
        }

        // Add user node and links
        if (mention.type === "profile") {
          userNodeId = mention.url; // If the mention is of type "profile" use it as the user node
        } else {
          userNodeId = mention.user_id || `${mention.user}-${mention.source}`;
          let existingUserNode = myData.nodes.find((node) => node.id === userNodeId || (node.user === userNodeId && node.type === "profile"));
          if (!existingUserNode) {
            existingUserNode = {
              id: userNodeId,
              name: mention.user,
              image: mention.avatar || preRenderedIcons["profile"],
              source: "User",
            };
            myData.nodes.push(existingUserNode);
          }
          myData.links.push({
            source: existingUserNode.id,
            target: mention.url,
          });
        }
        myData.links.push({
          source: platformNodeId,
          target: userNodeId,
        });
      }

      // Add narratives and links
      for (const narrative of mention.ai_filter?.results || []) {
        if (!narrativeNodes.has(narrative.key)) {
          narrativeNodes.add(narrative.key);
          myData.nodes.push({
            id: narrative.key,
            name: narrative.key,
            source: "Narrative",
          });
        }
        myData.links.push({
          source: "Root",
          target: narrative.key,
        });
        if (mention.type === "article") {
          myData.links.push({
            source: narrative.key,
            target: mention.url,
          });
        } else {
          myData.links.push({
            source: narrative.key,
            target: platformNodeId,
          });
        }
      }

      for (const similarMention of mention.similar || []) {
        if (mention.type === "profile") continue; // Skip the current mention if it's a profile
        if (similarMention.score < similarityThreshold) continue;
        const similarMentionData = sortedMentionsWithAssetAndNarrative.find((m) => m.url === similarMention.url);
        if (!similarMentionData || similarMentionData.type === "profile") continue; // Skip the similar mention if it's a profile

        const mentionDate = mention.creation_date || mention.detection_date || 0;
        const similarDate = similarMentionData.creation_date || similarMentionData.detection_date || 0;

        let source: string, target: string, similarity: number;

        if (mentionDate > similarDate) {
          source = similarMention.url;
          target = mention.url;
          similarity = similarMention.score;
        } else if (mentionDate < similarDate) {
          source = mention.url;
          target = similarMention.url;
          similarity = similarMention.score;
        } else {
          if (mention.url < similarMention.url) {
            source = mention.url;
            target = similarMention.url;
            similarity = similarMention.score;
          } else {
            source = similarMention.url;
            target = mention.url;
            similarity = similarMention.score;
          }
        }

        const existingLinkIndex = myData.links.findIndex((link) => link.source === source && link.target === target);
        if (existingLinkIndex === -1) {
          myData.links.push({
            source: source,
            target: target,
            similarity: similarity,
          });
        }
      }
    }

    return { myData };
  }, [sortedMentionsWithAssetAndNarrative, preRenderedIcons]);

  const rootNode = "Root";
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(() => {
    const initialExpandedNodes = new Set<string>([rootNode]);
    // Add all narrative nodes to the initial expanded nodes
    myData.nodes.forEach((node) => {
      if (node.source === "Narrative") {
        initialExpandedNodes.add(node.id);
      }
    });
    return initialExpandedNodes;
  });
  // BFS to derive "visible" nodes and links from expandedNodes
  const graphData = useMemo(() => {
    const adjList: Record<string, string[]> = {};
    myData.nodes.forEach((node) => {
      adjList[node.id] = [];
    });
    myData.links.forEach((link) => {
      // In this DAG, "link.source" represents the parent node and "link.target" represents the child node
      const sourceId = typeof link.source === "object" ? link.source.id : link.source;
      const targetId = typeof link.target === "object" ? link.target.id : link.target;
      if (!adjList[sourceId]) {
        adjList[sourceId] = [];
      }
      adjList[sourceId].push(targetId);
    });
    // BFS from the "Root" node, following expansions
    const visited = new Set<string>();
    const queue = [rootNode];
    visited.add(rootNode);
    while (queue.length > 0) {
      const nodeId = queue.shift()!;
      const children = adjList[nodeId] || [];
      // Only traverse children if nodeId is expanded
      if (expandedNodes.has(nodeId)) {
        for (const child of children) {
          if (!visited.has(child)) {
            visited.add(child);
            queue.push(child);
          }
        }
      }
    }
    // Filter myData to produce visible subgraph
    const visibleNodes = myData.nodes.filter((node) => visited.has(node.id));
    const visibleNodeIds = new Set(visibleNodes.map((node) => node.id));
    const visibleLinks = myData.links.filter((link) => {
      const sourceId = typeof link.source === "object" ? link.source.id : link.source;
      const targetId = typeof link.target === "object" ? link.target.id : link.target;
      return visibleNodeIds.has(sourceId) && visibleNodeIds.has(targetId);
    });
    return { nodes: visibleNodes, links: visibleLinks };
  }, [myData, expandedNodes, rootNode]);

  const handleNodeClick = (node: any) => {
    const mention = mentions.find((m) => m.url === node.id);
    if (mention) {
      setSidebarAlert(mention);
    }
    if (node.id !== "Root") {
      // Toggle expand/collapse
      setIsZoomedOut(false);
      setExpandedNodes((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(node.id)) {
          newSet.delete(node.id);
        } else {
          newSet.add(node.id);
        }
        if (node.source === "User") {
          setSelectedUserNode(newSet.has(node.id) ? node.id : null);
        }
        return newSet;
      });
    }
  };

  function renderNodeCanvasObject(node: any, ctx: CanvasRenderingContext2D, globalScale: number) {
    const isSelected = sidebarAlert?.url === node.id;
    const isUserNodeExpanded = expandedNodes.has(node.id) && node.source === "User";
    const isUserNodeSelected = selectedUserNode === node.id;
    const radius = 4 / globalScale;

    if (node.source === "Narrative") {
      const fontSize = radius * 2.5;
      ctx.font = `${fontSize}px Sans-Serif`;
      const textWidth = ctx.measureText(node.name).width;
      const padding = fontSize * 0.2;
      const backgroundDimensions = [textWidth + padding * 2, fontSize + padding * 2];
      ctx.fillStyle = stringToColor(node.name);
      ctx.fillRect(node.x - backgroundDimensions[0] / 2, node.y - backgroundDimensions[1] / 2, backgroundDimensions[0], backgroundDimensions[1]);
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(node.name, node.x, node.y);
      node.__bckgDimensions = backgroundDimensions;
      return;
    }

    if (isUserNodeExpanded && isUserNodeSelected) {
      ctx.beginPath();
      ctx.arc(node.x, node.y, radius * 3, 0, 2 * Math.PI, false);
      ctx.fillStyle = "rgba(254, 254, 26, 0.6)";
      ctx.fill();
    }

    if (isSelected) {
      ctx.beginPath();
      ctx.arc(node.x, node.y, radius * 3, 0, 2 * Math.PI, false);
      ctx.fillStyle = "rgba(153, 102, 255, 0.6)";
      ctx.fill();
    }

    let color = stringToColor(node.name);
    if (node.image) color = "rgba(0, 0, 0, 0)";
    if (node.id === "Root") color = nodeFillColor;
    ctx.beginPath();
    ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = color;
    ctx.fill();

    const img = imgCache[node.id];
    if (img) {
      const size = radius * 4;
      ctx.save();
      ctx.beginPath();
      ctx.arc(node.x, node.y, size / 2, 0, 2 * Math.PI, false);
      ctx.clip();
      ctx.drawImage(img, node.x - size / 2, node.y - size / 2, size, size);
      ctx.restore();
    }
  }

  useEffect(() => {
    myData.nodes.forEach((node) => {
      if (node.image && !imgCache[node.id]) {
        const img = new Image();
        img.src = node.image;
        img.onload = () => setImgCache((prevCache) => ({ ...prevCache, [node.id]: img }));
        img.onerror = () => {
          const fallbackImg = new Image();
          fallbackImg.src = preRenderedIcons["profile"];
          fallbackImg.onload = () => setImgCache((prevCache) => ({ ...prevCache, [node.id]: fallbackImg }));
        };
      }
    });
  }, [myData.nodes, imgCache, preRenderedIcons]);

  return (
    <Box>
      <Tooltip title="Filtered in the monitoring view">
        <Typography variant="h6" gutterBottom ml={GlobalSizes.gap}>
          AI Narrative Directed Acyclic Graph ({sortedMentionsWithAssetAndNarrative.length} alerts)
        </Typography>
      </Tooltip>
      <ForceGraph2D
        ref={graphRef}
        graphData={graphData}
        width={fullScreen ? window.innerWidth : GlobalSizes.cards.width}
        height={fullScreen ? window.innerHeight - 98 : 500}
        backgroundColor={theme.palette.background.paper}
        warmupTicks={100}
        cooldownTicks={2000}
        nodeColor={(node) => stringToColor(node.name)}
        nodeLabel={(node) => {
          if (node.id === "Root") return "";
          if (node.type === "profile") return `User - ${node.label} (Profile Alert)`;
          return `${node.source} - ${node.name}`;
        }}
        linkWidth={getLinkWidth}
        linkDirectionalParticles={getLinkParticles}
        linkDirectionalParticleWidth={getLinkParticleWidth}
        linkLabel={(link) => (link.similarity ? `${link.similarity?.toFixed(2) * 100 + "% similar"}` : "")}
        linkColor={(link) => {
          if (link.similarity) {
            if (link.similarity > 0.95) return severity.CRITICAL.color;
            if (link.similarity > 0.9) return severity.HIGH.color;
            if (link.similarity > 0.85) return severity.MEDIUM.color;
            return severity.LOW.color;
          }
          return linkFillColor;
        }}
        onEngineTick={() => {
          if (graphRef.current && !isZoomedOut) {
            graphRef.current.zoomToFit(500, 50);
            setIsZoomedOut(true);
          }
        }}
        onNodeClick={handleNodeClick}
        onNodeDragEnd={(node) => {
          node.fx = node.x;
          node.fy = node.y;
        }}
        nodeCanvasObject={renderNodeCanvasObject}
      />
      <MentionFocus />
    </Box>
  );
}

export default DirectedAcyclicGraph;
