import { createContext, useContext, useState, ReactNode, useEffect, useRef, useCallback } from "react";
import { ApiRequestData, DashboardState, MentionType } from "./state";
import { get, set } from "idb-keyval";
import { getTeam } from "./team-util";
import { useAuth0 } from "@auth0/auth0-react";
import isEqual from "lodash.isequal";

interface WsContextType {
  dashboardState: DashboardState;
  mentionsState: MentionType[];
  currentAlert?: MentionType;
  setCurrentAlert: React.Dispatch<React.SetStateAction<MentionType | undefined>>;
  sidebarAlert?: MentionType;
  setSidebarAlert: React.Dispatch<React.SetStateAction<MentionType | undefined>>;
  isLoadingMentions: boolean;
  emitNewData: (data: ApiRequestData) => void;
  updateAlerts: (newMention: MentionType) => void;
  updateDashboard: (newData: DashboardState) => void;
  socketRef: React.MutableRefObject<WebSocket | null>;
}

const WsContext = createContext<WsContextType | undefined>(undefined);

export const useWsContext = (): WsContextType => {
  const context = useContext(WsContext);
  if (!context) {
    throw new Error("useClientContext must be used within a ClientContextProvider");
  }
  return context;
};

export const WsContextProvider = ({ children }: { children: ReactNode }) => {
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const [dashboardState, setDashboardState] = useState<DashboardState>({});

  const [mentionsState, setMentionsState] = useState<MentionType[]>([]);
  const [currentAlert, setCurrentAlert] = useState<MentionType>();
  const [sidebarAlert, setSidebarAlert] = useState<MentionType>();

  const [isLoadingMentions, setIsLoadingMentions] = useState<boolean>(true);

  const socketRef = useRef<WebSocket | null>(null);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const indexDBDashboardKey = `v4_${user?.email}_${getTeam() || user?.email}_dashboard`;
  const indexDBMentionsKey = `v4_${user?.email}_${getTeam() || user?.email}_mentions`;

  const getToken = useCallback(async () => {
    return await getAccessTokenSilently({
      authorizationParams: { audience: `https://1frxzpgh24.execute-api.us-east-1.amazonaws.com/Prod/` },
    });
  }, [getAccessTokenSilently]);

  const emitNewData = async (data: ApiRequestData) => {
    console.log("Emitting new data to WebSocket", data);
    const token = await getToken();
    const team = getTeam() || user?.email;

    if (socketRef.current?.readyState !== WebSocket.OPEN) {
      console.log("WebSocket not open. Reinitializing");
      await initializeWebSocket();
    }

    try {
      if (data.action === "disconnect") {
        socketRef.current?.close();
        socketRef.current = null;
      } else {
        socketRef.current?.send(JSON.stringify({ ...data, customer: { id: user?.email, team }, token: `Bearer ${token}` }));
      }
    } catch (error) {
      console.error("Error sending data to WebSocket", error);
    }
  };

  const updateAlerts = (newMention: MentionType) => {
    setCurrentAlert((prev) => {
      if (newMention.url === prev?.url) {
        return newMention;
      }
      return prev;
    });

    setSidebarAlert((prev) => {
      if (newMention.url === prev?.url) {
        return newMention;
      }
      return prev;
    });

    setMentionsState((prev) => {
      const updatedMentions = [...prev];
      let isStateUpdated = false;
      const existingIndex = updatedMentions.findIndex((mention) => mention.url === newMention.url);
      if (existingIndex !== -1) {
        if (!isEqual(updatedMentions[existingIndex], newMention)) {
          updatedMentions[existingIndex] = newMention;
          isStateUpdated = true;
        }
      } else {
        updatedMentions.push(newMention);
        isStateUpdated = true;
      }
      return isStateUpdated ? updatedMentions : prev;
    });
  };

  const deleteAlert = (url: string) => {
    setMentionsState((prev) => {
      return prev.filter((mention) => mention.url !== url);
    });
  };

  const updateDashboard = (newData: DashboardState) => {
    setDashboardState((prev) => {
      return { ...prev, ...newData };
    });
  };

  const initializeWebSocket = useCallback(async () => {
    if (socketRef.current) {
      console.log("WebSocket already initialized");
      return;
    }

    if (user?.email) {
      const token = await getToken();
      const team = getTeam() || user?.email;
      const socket = new WebSocket(`wss://ww7si9kua5.execute-api.us-east-1.amazonaws.com/production?id=${user.email}&team=${team}&token=Bearer ${token}`);
      socketRef.current = socket;

      socket.addEventListener("open", () => {
        console.log("WebSocket opened");
        socket.send(
          JSON.stringify({
            action: "setConnection",
            customer: { id: user?.email, team },
            token: `Bearer ${token}`,
          })
        );
      });

      socket.addEventListener("message", (event) => {
        const { data } = JSON.parse(event.data);
        if (!data?.customer) {
          console.error("No customer data received");
          return;
        }

        console.log("customer  ", " id:", data?.customer?.id, " team:", data?.customer?.team, " connectionId:", data?.customer?.connectionId);

        if (data?.dashboard) {
          if (data.dashboard.customer_id !== data.customer.team || user?.email !== data.customer.id) {
            console.error("Invalid customer data received");
            return;
          }
          setDashboardState((prev) => {
            return { ...prev, ...data.dashboard };
          });
        }

        if (data?.saved_views) {
          if (team !== data.customer.team || user?.email !== data.customer.id) {
            console.error("Invalid customer data received");
            return;
          }
          updateDashboard({ saved_views: data.saved_views });
        }

        if (data?.assets) {
          if (team !== data.customer.team || user?.email !== data.customer.id) {
            console.error("Invalid customer data received");
            return;
          }
          updateDashboard({ assets: data.assets });
        }

        if (data?.ai_questions) {
          if (team !== data.customer.team || user?.email !== data.customer.id) {
            console.error("Invalid customer data received");
            return;
          }
          updateDashboard({ ai_questions: data.ai_questions });
        }

        if (data?.alerts) {
          if (data?.status === "completed" && !data.alerts.length) {
            setIsLoadingMentions(false);
            return;
          }
          data.alerts.forEach((newAlert: MentionType) => {
            if (newAlert.customer_id !== data.customer.team || user?.email !== data.customer.id) {
              console.error("Invalid alert data received");
              return;
            }
            updateAlerts(newAlert);
          });
        }

        if (data?.updatedAlert) {
          if (data.updatedAlert.customer_id !== data.customer.team || user?.email !== data.customer.id) {
            console.error("Invalid alert data received");
            return;
          }
          console.log("Updating alert", data.updatedAlert);
          updateAlerts(data.updatedAlert);
        }

        if (data?.removedAlert) {
          if (data.removedAlert.customer_id !== data.customer.team || user?.email !== data.customer.id) {
            console.error("Invalid alert data received");
            return;
          }
          deleteAlert(data.removedAlert);
        }

        if (data?.error) {
          console.log("Server error : ", data.error);
          const team = getTeam() || user?.email;
          if (data.customer.id !== user?.email || data.customer.team !== team) {
            console.error("mismatch in user email");
            return;
          }
          if (data.statusCode === 401 || data.statusCode === 403) {
            localStorage.clear();
            if (data.statusCode === 401) {
              console.error("User unauthorized");
              logout({ logoutParams: { returnTo: window.location.origin } });
            } else {
              console.error("User Forbidden");
              window.location.reload();
            }
          } else {
            console.log("Invalidate data");
            socket.send(
              JSON.stringify({
                action: "setConnection",
                customer: { id: user?.email, team },
                token: `Bearer ${token}`,
              })
            );
          }
        }
      });

      socket.addEventListener("close", () => {
        console.log("WebSocket closed");
        socketRef.current = null;

        reconnectTimeoutRef.current = setTimeout(() => {
          initializeWebSocket();
        }, 5000);
      });

      socket.addEventListener("error", (error) => {
        console.error("WebSocket error", error);
        socketRef.current = null;
      });
    }
  }, [user?.email, logout, getToken]);

  useEffect(() => {
    const loadCachedData = async () => {
      const oneMonthAgo = Date.now() - 30 * 24 * 60 * 60 * 1000;
      const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;

      let filteredItemsCount = 0;

      const cachedDashboard = await get<DashboardState>(indexDBDashboardKey);
      const cachedMentions = await get<MentionType[]>(indexDBMentionsKey);

      const validCachedMentions = cachedMentions?.filter((item) => {
        if (!item.detection_date) return false;
        if (item.flagged) return true;
        if (item.detection_date > oneMonthAgo) {
          if (filteredItemsCount < 1000 || item.detection_date > oneWeekAgo) {
            filteredItemsCount++;
            return true;
          }
        }
        if (item.parent_mention) return true;
        if (item.hidden) return false;
        if (item.ai_filter?.status === "success") return true;
        if (item.manual_severity) return true;
        return false;
      });

      if (cachedDashboard) setDashboardState(cachedDashboard);
      if (validCachedMentions?.length) {
        setMentionsState(validCachedMentions);
      }
    };

    loadCachedData();
  }, [indexDBDashboardKey, indexDBMentionsKey]);

  useEffect(() => {
    if (!socketRef.current) {
      initializeWebSocket();
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && !socketRef.current) {
        initializeWebSocket();
      }
    };

    const handleWindowFocus = () => {
      if (!socketRef.current) {
        initializeWebSocket();
      }
    };

    window.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleWindowFocus);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleWindowFocus);

      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }

      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [initializeWebSocket]);

  useEffect(() => {
    const setDashboardInIndexedDB = async () => {
      const team = getTeam() || user?.email;
      if (Object.keys(dashboardState).length) {
        if (dashboardState.customer_id !== team) {
          console.log("Mismatch in team and customer_id");
          return;
        }
        try {
          set(indexDBDashboardKey, dashboardState);
        } catch (error) {
          console.error("Error setting dashboard in indexedDB", error);
        }
      }
    };
    setDashboardInIndexedDB();
  }, [dashboardState, user?.email, indexDBDashboardKey]);

  useEffect(() => {
    const setMentionsInIndexedDB = async () => {
      const team = getTeam() || user?.email;
      if (mentionsState.length) {
        const validMentions = mentionsState.filter((mention) => mention.customer_id === team);
        try {
          set(indexDBMentionsKey, validMentions);
        } catch (error) {
          console.error("Error setting mentions in indexedDB", error);
        }
      }
    };
    setMentionsInIndexedDB();
  }, [mentionsState, user?.email, indexDBMentionsKey]);

  return (
    <WsContext.Provider
      value={{
        dashboardState,
        mentionsState,
        currentAlert,
        setCurrentAlert,
        sidebarAlert,
        setSidebarAlert,
        isLoadingMentions,
        emitNewData,
        updateAlerts,
        updateDashboard,
        socketRef,
      }}
    >
      {children}
    </WsContext.Provider>
  );
};
