import { useCallback } from "react";
import { useWsContext } from "./ws-context";

export const useNetworkChart = () => {
  const { sidebarAlert } = useWsContext();

  const getLinkWidth = useCallback(
    (connectedValue: number, defaultValue: number) => {
      return (link: any) => {
        if (!sidebarAlert) return defaultValue;
        return link.source.id === sidebarAlert.url || link.target.id === sidebarAlert.url ? connectedValue : defaultValue;
      };
    },
    [sidebarAlert]
  );

  return {
    getLinkParticles: getLinkWidth(4, 0),
    getLinkWidth: getLinkWidth(2, 1),
    getLinkParticleWidth: getLinkWidth(4, 0),
  };
};
