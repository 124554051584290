import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import HomeFilters from "./home/HomeFilters";
import InquiriesCharts from "./InquiriesCharts";
import { MentionType } from "../state";
import { Slide, useTheme } from "@mui/material";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function FullScreenChartsDialog({
  filteredMentions,
  setFilteredMentions,
  setShowFullScreenCharts,
  showFullScreenCharts = false,
}: {
  filteredMentions: MentionType[];
  setFilteredMentions?: React.Dispatch<React.SetStateAction<MentionType[]>> | undefined;
  setShowFullScreenCharts: React.Dispatch<React.SetStateAction<boolean>>;
  showFullScreenCharts?: boolean;
}) {

  const theme = useTheme();

  return (
    <Dialog fullScreen open={showFullScreenCharts} onClose={() => setShowFullScreenCharts(false)} TransitionComponent={Transition}>
      {filteredMentions.length < 1 && <div> nothing to show</div>}
      <Box sx={{ backgroundColor: theme.palette.background.paper }}>
        <Box
          sx={{
            position: "absolute",
            top: 11,
            right: 70,
          }}
        >
          {setFilteredMentions && <HomeFilters filteredMentions={filteredMentions} setFilteredMentions={setFilteredMentions} />}
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
          }}
        >
          <IconButton edge="start" color="inherit" onClick={() => setShowFullScreenCharts(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>

        <InquiriesCharts mentions={filteredMentions} setShowFullScreenCharts={setShowFullScreenCharts} showFullScreenCharts={showFullScreenCharts} />
      </Box>
    </Dialog>
  );
}
