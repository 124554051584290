import { GlobalSizes } from "../../size";


const AssetHighlighter = ({ text, asset }: { text?: string; asset: string }) => {
  if (!text || !asset) return null;
  const trimmedAsset = asset?.startsWith('"') && asset?.endsWith('"') ? asset.slice(1, -1) : asset;
  const startIndex = text.toLowerCase().indexOf(trimmedAsset.toLowerCase());
  if (startIndex === -1) return <span>{text}</span>;

  return (
    <span>
      {text.substring(0, startIndex)}
      <span
        style={{
          color: "black",
          fontWeight: "bold",
          backgroundColor: "#ffef75",
          borderRadius: GlobalSizes.borderRadius,
        }}
      >
        {text.substring(startIndex, startIndex + trimmedAsset.length)}
      </span>
      {text.substring(startIndex + trimmedAsset.length)}
    </span>
  );
};

export default AssetHighlighter;
