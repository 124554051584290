import { Avatar, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { GlobalSizes } from "../../size";
import { MentionType } from "../../state";
import AssetHighlighter from "../assets/AssetHighlighter";
import { unixToLocaleDate } from "../../labels";

function GeneralView({ alert }: { alert?: MentionType }) {
  if (!alert) return null;
  return (
    <div>
      <Card sx={{ my: GlobalSizes.mediumGap }}>
          <CardContent>
            <CardHeader
              sx={{ pl: GlobalSizes.none }}
              avatar={<Avatar alt={alert?.user} src={alert?.avatar} />}
              title={alert?.user}
              subheader={unixToLocaleDate(alert.creation_date)}
            />
            <Typography variant="body2" className="report_description" color="text.secondary">
              <AssetHighlighter text={alert?.description_short} asset={alert?.asset || ""} />
            </Typography>
            <Typography variant="body2" className="report_description" color="text.secondary">
              <a
                href={alert?.url}
                target="_blank"
                rel="noreferrer"
                style={{ display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: GlobalSizes.fullSize }}
              >
                {alert?.url}
              </a>
            </Typography>
          </CardContent>
      </Card>
    </div>
  );
}

export default GeneralView;
