import { useMemo } from "react";
import { LineChart } from "@mui/x-charts";
import { MentionType } from "../../state";
import { GlobalSizes } from "../../size";
import CustomLegend from "./CustomLegend";
import { Box, Typography } from "@mui/material";

type InquiriesDatasetType = {
  date: number;
  [key: string]: number;
}[];

interface InquiriesLineChartProps {
  mentions: MentionType[];
  stringToColor: (str: string) => string;
}

const buildLineChartData = (mentions: MentionType[], inquiries: string[]) => {
  const dataset = mentions.reduce<{ [date: number]: { [key: string]: number } }>((acc, mention) => {
    if (!mention.creation_date) return acc;
    const date = new Date(mention.creation_date).setHours(0, 0, 0, 0);
    if (!acc[date]) acc[date] = {};
    mention.ai_filter?.results?.forEach((result) => {
      if (inquiries.includes(result.key)) {
        acc[date][result.key] = (acc[date][result.key] || 0) + 1;
      }
    });
    return acc;
  }, {});

  const filteredData: InquiriesDatasetType = Object.entries(dataset)
    .map(([timestamp, data]) => ({
      date: Number(timestamp),
      ...Object.fromEntries(
        Object.entries(data)
          .sort(([, a], [, b]) => b - a)
          .slice(0, 5)
      ),
    }))
    .sort((a, b) => a.date - b.date);

  return filteredData;
};

function InquiriesLineChart({ mentions, stringToColor }: InquiriesLineChartProps) {
  const { lineChartData, relevantInquiries } = useMemo(() => {
    const allInquiries = mentions.reduce((acc: string[], mention) => {
      mention.ai_filter?.results?.forEach((result) => {
        if (!acc.includes(result.key)) {
          acc.push(result.key);
        }
      });
      return acc;
    }, []);

    const datasetArr = buildLineChartData(mentions, allInquiries);

    const relevantInquiries = Array.from(new Set(datasetArr.flatMap((data) => Object.keys(data)))).filter((key) => key !== "date");

    const lineChartData: InquiriesDatasetType = datasetArr.map((data) => {
      relevantInquiries.forEach((key) => {
        if (!data[key]) {
          data[key] = 0;
        }
      });
      return data;
    });

    return { lineChartData, relevantInquiries };
  }, [mentions]);

  const series = useMemo(
    () => relevantInquiries.map((key) => ({ id: key, label: key, dataKey: key, stack: "total", area: true, showMark: false, color: stringToColor(key) })),
    [relevantInquiries, stringToColor]
  );

  return (
    <Box width={GlobalSizes.cards.width}>
      <Typography variant="h6" gutterBottom ml={GlobalSizes.gap}>
        Trending Narratives ({mentions.length} alerts)
      </Typography>
      <CustomLegend labels={series.map((s) => ({ name: s.label, color: s.color }))} />
      <LineChart
        dataset={lineChartData}
        slotProps={{ legend: { hidden: true } }}
        xAxis={[
          {
            id: "Days",
            dataKey: "date",
            scaleType: "time",
            valueFormatter: (date) => new Date(date).toLocaleDateString(),
          },
        ]}
        series={series}
        height={GlobalSizes.chartHeight}
        skipAnimation
      />
    </Box>
  );
}

export default InquiriesLineChart;
