import "./App.css";
import { Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Monitoring from "./Pages/Monitoring";
import LoginPage from "./Pages/LoginPage";
import Investigation from "./Pages/Investigation";
import Action from "./Pages/Action";
import SidePanel from "./Components/SidePanel";
import { Alert, CircularProgress, Stack, Typography } from "@mui/material";
import Home from "./Pages/Home";
import { useEffect } from "react";
import Team from "./Pages/Team";
import SiemIntegration from "./Pages/SiemIntegration";
import { GlobalSizes } from "./size";
import Assets from "./Pages/Assets";
import { LicenseInfo } from "@mui/x-license";
import AI from "./Pages/AI";
import Mixpanel from "./mixpanel";
import ButtonClickTracker from "./ButtonClickTracker";
import { setTeam } from "./team-util";
import LogRocket from "logrocket";
import { useWsContext } from "./ws-context";
import { useNavigate } from "react-router-dom";
import { AccessibleTeamsType } from "./state";
import { getTeam } from "./team-util";
import Api from "./Pages/Api";

function App() {
  if (window.location.hostname !== "localhost") {
    LogRocket.init("spkurz/brinker", {
      serverURL: `https://nd74aect4fakcvoqtpntazanim0delik.lambda-url.us-east-1.on.aws/logrocket`,
    });
  }
  LicenseInfo.setLicenseKey("2a33a8453f4638a3b3e7ceb6915f51f1Tz04ODg4NSxFPTE3NDUzMTQ0MTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

  const { isLoading, user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const { dashboardState } = useWsContext();

  useEffect(() => {
    const team = new URLSearchParams(window.location.search).get("team");
    if (team) {
      setTeam(team);
    }

    if (user) {
      if (user.email) {
        LogRocket.identify(user.email);
      }

      Mixpanel.user(user);
      Mixpanel.track("App Enter", {
        distinct_id: user.email,
        page: window.location.pathname,
      });
    }
  }, [user, isLoading, isAuthenticated]);

  useEffect(() => {
    if (!isLoading && isAuthenticated && !getTeam()) {
      const handleTeamAtLogin = () => {
        let hasPrivateTeam = false;
        if (dashboardState?.accessibleTeams && user) {
          const teams: AccessibleTeamsType = dashboardState?.accessibleTeams.filter((team) => {
            if (team.customerId !== user.email) {
              return true;
            } else {
              hasPrivateTeam = true;
              return false;
            }
          });
          console.log("teams", teams);
          if (teams.length >= 1) {
            teams.sort((a, b) => (b?.team?.users?.length || 0) - (a?.team?.users?.length || 0));
            setTeam(teams[0].customerId);
            window.location.href = window.location.origin + "/?team=" + teams[0].customerId;
            return;
          }
          if (!teams.length) {
            if (hasPrivateTeam) {
              setTeam(user.email as string);
              window.location.href = window.location.origin + "/assets?team=" + user.email;
            } else {
              navigate("/team");
            }
            return;
          }
        }
      };
      handleTeamAtLogin();
    }
  }, [isLoading, isAuthenticated, dashboardState?.accessibleTeams, navigate, user]);

  if (isLoading) {
    return (
      <Stack className="login_loader">
        <CircularProgress />
      </Stack>
    );
  }

  if (!isLoading && !isAuthenticated) {
    return <LoginPage />;
  }

  return (
    <div>
      <ButtonClickTracker />
      {
        <div className="App">
          <div style={{ display: "flex", minHeight: GlobalSizes.fullScreenHeight }}>
            <SidePanel user={user} />
            <Stack width={GlobalSizes.fullSize}>
              {!user?.email_verified && (
                <Alert severity="warning">
                  <Typography fontWeight={"bold"} fontSize={GlobalSizes.bigFontSize}>
                    To continue using Brinker dashboard, please verify your email!
                  </Typography>
                </Alert>
              )}
              <div style={{ padding: GlobalSizes.none, width: GlobalSizes.fullSize }}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/monitoring" element={<Monitoring />} />
                  <Route path="/ai" element={<AI />} />
                  <Route path="/assets" element={<Assets />} />
                  <Route path="/investigation" element={<Investigation />} />
                  <Route path="/action" element={<Action />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/siem-integration" element={<SiemIntegration />} />
                  <Route path="/api" element={<Api />} />
                </Routes>
              </div>
            </Stack>
          </div>
        </div>
      }
    </div>
  );
}

export default App;
