import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  Alert,
  useMediaQuery,
} from "@mui/material";

const ApiDocumentation = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const boxColor = prefersDarkMode ? "#1f1f1f" : "#efefef";
  return (
    <div>
      <Alert severity="info" style={{ marginLeft: "25px", marginRight: "25px", maxWidth: "1500px" }}>
        Contact Brinker to obtain your API key and API url.
      </Alert>
      <Box p={3}>
        {/* Authentication Section */}
        <Card variant="outlined" sx={{ mb: 4, maxWidth: "1500px" }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Authentication
            </Typography>
            <Typography variant="body1" paragraph>
              Include the API key provided by Brinker in the request headers.
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Header</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Description</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>Authorization</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{"{{your-api-key}}"}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>

        {/* Alerts Section */}
        <Card variant="outlined" sx={{ maxWidth: "1500px" }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Alerts
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Box sx={{ fontWeight: "bold" }}>
                {" "}
                <u>Method:</u> <span style={{ color: "#eb9234", fontWeight: "700" }}>POST</span>
              </Box>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Box sx={{ fontWeight: "bold" }}>
                <u>Path:</u> /alerts
              </Box>
            </Typography>

            {/* Body Parameters */}
            <Typography variant="h6" gutterBottom>
              Body
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Key</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Description</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>limit</TableCell>
                    <TableCell>
                      An integer greater than 0 that limits the number of alerts fetched in a single request. <em>(Optional)</em>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>nextPageToken</TableCell>
                    <TableCell>
                      The nextPageToken is included in the response when there are additional pages. Pass it in the request body to retrieve the next page.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <Typography variant="h6" gutterBottom>
              Pagination:
            </Typography>
            <Typography variant="body1" paragraph>
              If a single request does not retrieve all alerts, the response will include a nextPageToken. To fetch subsequent pages, include the nextPageToken
              in the request body of your next request. The final page in the results will not include a nextPageToken, indicating there are no more pages to
              retrieve.
            </Typography>
            {/* Request Example */}
            <Typography variant="h6" gutterBottom>
              Request Body Example:
            </Typography>
            <Box
              component="pre"
              sx={{
                backgroundColor: boxColor,
                padding: 2,
                borderRadius: 2,
                whiteSpace: "pre-wrap",
              }}
            >
              {`{
    "limit": 3,
    "nextPageToken": "eyJ1cmwiOiJodHRwczovL3guY29tL2VybGljaHlhL3N0YXR1cy8xODE3MzQxOTAxNTUwMjU2NTA1IiwiY3VzdG9tZXJfaWQiO"
}`}
            </Box>

            {/* Response Example */}
            <Typography variant="h6" gutterBottom>
              Response Body Example:
            </Typography>
            <Box
              component="pre"
              sx={{
                backgroundColor: boxColor,
                padding: 2,
                borderRadius: 2,
                whiteSpace: "pre-wrap",
              }}
            >
              {`{
    "customerId": "abcde12345",
    "count": 3,
    "alerts": [
        {
            "exampleKey1": 1,
            "exampleKey2": 2
        },
        {
            "exampleKey1": 1,
            "exampleKey2": 2
        },
        {
            "exampleKey1": 1,
            "exampleKey2": 2
        }
    ],
    "nextPageToken": "eyJ1cmwiOiJodHRwczovL3d3dy50aGVndWFyZGlhbi5jb20vd29ybGQvYXJ0aWNsZS8yMDI0L3Nl"
}`}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default ApiDocumentation;
