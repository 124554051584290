import { MentionType } from "../../state";
import { Typography, Box } from "@mui/material";
import { GlobalSizes } from "../../size";

const summarizeMetaData = (alert: MentionType) => {
  const { creation_date, source, user, description_short, url, impressions, impressions_type, type } = alert;

  let creationDateSection;
  if (creation_date) {
    const date = new Date(creation_date);
    const formattedDate = date.toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" });
    const formattedTime = date.toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" });
    creationDateSection = `On ${formattedDate} at ${formattedTime}`;
  } else {
    creationDateSection = "On an unknown date";
  }

  const formattedImpressionsType = impressions ? impressions_type?.split("_")[1] : "";
  const impressionsSection = `The ${type?.toLowerCase()} has ${impressions?.toLocaleString() || 'unknown number of'} ${formattedImpressionsType}.`;

  let formattedSource = source;

  if (formattedSource) {
    formattedSource = formattedSource.split(".")[0];
    if (formattedSource.toLowerCase().includes("twitter")) {
      formattedSource = "X";
    }
  }

  const summary = (
    <>
      {`${creationDateSection}, `}
      <Typography component="span" sx={{ textTransform: "capitalize" }}>
        {`${formattedSource} `}
      </Typography>
      {`user ${user || ""} shared content stating "${description_short || "..."}" [${url}]. ${impressionsSection}`}
    </>
  );

  return summary;
};
function Overview({ alert }: { alert?: MentionType }) {
  if (!alert?.mitigation_general) return null;
  return (
    <Box mt={GlobalSizes.gap}  sx={{ 
      maxWidth: GlobalSizes.fullSize, 
      wordWrap: "break-word",
    }}>
      <Typography variant="h4">Overview</Typography>
      <Typography style={{ whiteSpace: "pre-line", marginTop: GlobalSizes.largeGap }}>{summarizeMetaData(alert)}</Typography>
      <Typography style={{ whiteSpace: "pre-line", marginTop: GlobalSizes.largeGap }}>{alert.mitigation_general}</Typography>
    </Box>
  );
}

export default Overview;
