import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useWsContext } from "../ws-context";
import { GlobalSizes } from "../size";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import Avatar from "@mui/material/Avatar";

export default function AlertSelect({ destination }: { destination: string }) {
  const { mentionsState, setCurrentAlert, currentAlert } = useWsContext();
  const nav = useNavigate();

  const alerts = useMemo(
    () =>
      mentionsState
        .filter((m) => m.flagged || m.ai_filter?.status === "success")
        .sort((b, a) => (a.flag_date || 0) - (b.flag_date || 0))
        .slice(0, 100),
    [mentionsState]
  );

  return (
    <Autocomplete
      id="alert-select"
      value={currentAlert || null}
      sx={{ width: GlobalSizes.fullSize }}
      options={alerts}
      autoHighlight
      getOptionLabel={(alert) => {
        let prefix = "";
        if (alert.title) {
          prefix += `${alert.title}: `;
        } else if (alert.user) {
          prefix += `${alert.user}: `;
        }
        return `${prefix}${alert.description_short ? `${alert.description_short} - ` : ""}${alert.url}`;
      }}
      onChange={(event, newValue) => {
        if (!newValue) return;
        setCurrentAlert(newValue);
        nav(`/${destination}?url=${encodeURIComponent(newValue.url)}`, { replace: true });
      }}
      renderOption={(props, alert) => {
        let dateString = null;
        if (alert.flagged && alert.flag_date) {
          dateString = " - flagged " + new Date(alert.flag_date).toLocaleDateString(undefined, { month: "short", day: "numeric" });
        }
        return (
          <Box key={alert.url} component="li" sx={{ display: "flex", alignItems: "center", gap: GlobalSizes.smallGap, cursor: "pointer" }} {...props}>
            <Avatar alt={alert.user} src={alert.avatar} />
            <Stack sx={{ minWidth: GlobalSizes.none }}>
              <Box>
                {alert.title && (
                  <Typography fontWeight="bold" variant="caption" noWrap>
                    {alert.title}
                  </Typography>
                )}
                {alert.user && !alert.title && (
                  <Typography fontWeight="bold" variant="caption" noWrap>
                    {alert.user}
                  </Typography>
                )}
                <Typography fontWeight="light" variant="caption" noWrap>
                  {dateString}
                </Typography>
              </Box>
              <Typography
                noWrap
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {alert.description_short}
              </Typography>
              <Typography fontWeight="light" variant="caption" sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                {alert.url}
              </Typography>
            </Stack>
          </Box>
        );
      }}
      renderInput={(params) => <TextField {...params} label="Select Alert" />}
    />
  );
}
