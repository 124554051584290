import { Box, Button, CircularProgress, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { MitigationKeys } from "../../Pages/Action";
import { GlobalSizes } from "../../size";
import { Refresh } from "@mui/icons-material";
import { useState } from "react";
import { MITIGATIONS } from "../../labels";
import { useWsContext } from "../../ws-context";

interface MitigationTabProps {
  mitigation: MitigationKeys;
  mitigationText?: string;
  alertUrl: string;
  showReplyInPlatform: boolean;
}
function TabPanel({ mitigation, mitigationText, alertUrl, showReplyInPlatform }: MitigationTabProps) {
  const { emitNewData, socketRef, currentAlert } = useWsContext();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function copyMitigationText() {
    if (!mitigationText) return;
    navigator.clipboard.writeText(mitigationText);
  }

  function openReplyInPlatform() {
    if (!alertUrl || !mitigationText) return;
    const tweetNumber = alertUrl.split("/").pop();
    if (!tweetNumber) return;
    const replyUrl = `https://twitter.com/intent/tweet?in_reply_to=${tweetNumber}&text=${encodeURIComponent(mitigationText)}`;
    window.open(replyUrl, "_blank");
  }

  const handleMitigation = async (mitigation: MitigationKeys) => {
    if (!currentAlert) return null;
    console.log({ currentAlert });
    console.log("Mitigation request");
    setIsLoading(true);
    setIsError(false);
    emitNewData({ action: "mitigation", url: currentAlert.url, promptId: MITIGATIONS[mitigation].promptId });
    const handleWebSocketMessage = (event: MessageEvent) => {
      const { data } = JSON.parse(event.data);
      if (data?.updatedAlert?.url === currentAlert.url && data.updatedAlert[MITIGATIONS[mitigation].promptId]) {
        setIsLoading(false);
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      }
      if (data?.error?.details?.action === "mitigation" && data.error.details.url === currentAlert.url) {
        setIsLoading(false);
        setIsError(true);
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      }
    };
    socketRef.current?.addEventListener("message", handleWebSocketMessage);
  };

  return (
    <Paper sx={{ p: 2 }}>
      {mitigationText ? (
        <Box>
          <Typography sx={{ mb: 1, whiteSpace: "pre-wrap" }}>{mitigationText}</Typography>
          <Tooltip title="Try again" placement="top">
            <Button
              sx={{ borderTopLeftRadius: GlobalSizes.none, borderBottomLeftRadius: GlobalSizes.none }}
              disabled={isLoading}
              onClick={() => handleMitigation(mitigation)}
            >
              {isLoading ? <CircularProgress size={24} /> : <Refresh />}
            </Button>
          </Tooltip>
          <Button onClick={() => copyMitigationText()}>Copy to Clipboard</Button>
          {showReplyInPlatform && <Button onClick={openReplyInPlatform}>Reply in Platform</Button>}
        </Box>
      ) : (
        <Stack direction="row" spacing={2}>
          <Button variant="contained" size="large" disabled={isLoading} onClick={() => handleMitigation(mitigation)}>
            {`Generate ${mitigation}`}
          </Button>
          {isLoading && <CircularProgress />}
        </Stack>
      )}
      {isError && <Typography color="error">Failed generating mitigation, please try again</Typography>}
    </Paper>
  );
}

export default TabPanel;
