import { Box, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface LinkButtonProps {
  destination: string;
  label: string;
  icon: React.ReactNode;
}

function LinkButton({ destination, label, icon }: LinkButtonProps) {
  return (
    <Box sx={{ display: "flex" }}>
      <RouterLink to={destination}>
        <Button startIcon={icon} variant="contained">{label}</Button>
      </RouterLink>
    </Box>
  );
}

export default LinkButton;
