import { Typography, Container } from "@mui/material";
import InquiriesCharts from "../Components/InquiriesCharts";
import { GlobalSizes, pagesContainerMargins } from "../size";
import { UsageStats } from "../Components/home/UsageStats";
import HomeFilters from "../Components/home/HomeFilters";
import { useState } from "react";
import { FullScreenChartsDialog } from "../Components/FullScreenChartsDialog";
import { MentionType } from "../state";


function Home() {
  const [filteredMentions, setFilteredMentions] = useState<MentionType[]>([]);
  const [showFullScreenCharts, setShowFullScreenCharts] = useState(false);

  return (
    <div>
      {!showFullScreenCharts ? (
        <Container sx={{ ...pagesContainerMargins, minWidth: GlobalSizes.fullSize }}>
          <Typography variant="h4" gutterBottom>
            Welcome to Brinker.ai
          </Typography>
          <HomeFilters filteredMentions={filteredMentions} setFilteredMentions={setFilteredMentions} />
          <UsageStats filteredMentions={filteredMentions} />
          <InquiriesCharts mentions={filteredMentions} setShowFullScreenCharts={setShowFullScreenCharts} />
          <Typography fontSize={8}>
            <a href="/push-data.json" target="_blank" rel="noopener noreferrer">
              Version data
            </a>
          </Typography>
        </Container>
      ) : (
        <FullScreenChartsDialog
          filteredMentions={filteredMentions}
          setFilteredMentions={setFilteredMentions}
          showFullScreenCharts={showFullScreenCharts}
          setShowFullScreenCharts={setShowFullScreenCharts}
        />
      )}
    </div>
  );
}

export default Home;
