import FlagIcon from "@mui/icons-material/Flag";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalSizes } from "../size";
import { MentionType } from "../state";
import { useWsContext } from "../ws-context";
import { labels } from "../labels";

export default function StatusButtons({
  alert,
  nextAlerts,
  setNextAlerts,
}: {
  alert?: MentionType;
  nextAlerts: MentionType[];
  setNextAlerts?: React.Dispatch<React.SetStateAction<MentionType[]>>;
}) {
  const { updateAlerts, dashboardState, emitNewData, setCurrentAlert, setSidebarAlert } = useWsContext();

  const nav = useNavigate();
  const location = useLocation();

  const updateAlertRequest = useCallback(
    async (mentionToUpdate: Partial<MentionType>) => {
      const mention = {
        ...mentionToUpdate,
        customer_id: dashboardState.customer_id,
      };

      emitNewData({ action: "updateAlert", mention });
    },
    [dashboardState.customer_id, emitNewData]
  );

  const handleHiddenAlert = useCallback(() => {
    if (!alert) return;
    updateAlertRequest({ url: alert.url, hidden: !alert.hidden });
    updateAlerts({ ...alert, hidden: !alert.hidden });
    alert.hidden = !alert.hidden;
    if (alert.hidden && nextAlerts.length > 0 && location.pathname === `/${labels.monitoring.title.toLowerCase()}`) {
      const currentAlertIndex = nextAlerts.findIndex((a) => a.url === alert.url);
      setNextAlerts?.((prev) => prev.filter((a) => a.url !== alert.url));
      if (currentAlertIndex > -1) {
        if (nextAlerts.length > 1) {
          let currentAlert;
          if (currentAlertIndex === nextAlerts.length - 1) {
            currentAlert = nextAlerts[currentAlertIndex - 1];
          } else {
            currentAlert = nextAlerts[currentAlertIndex + 1];
          }          
          setSidebarAlert(currentAlert);
          updateAlerts({ ...currentAlert, read: true });
          return;
        }
        setSidebarAlert(undefined);
      }
    }
  }, [alert, location.pathname, nextAlerts, setSidebarAlert, updateAlertRequest, updateAlerts, setNextAlerts]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const activeElement = document.activeElement as HTMLElement;
      if (activeElement.tagName !== "TEXTAREA" && activeElement.tagName !== "INPUT") {
        if (event.key === "Y" || event.key === "y" || event.key === "ט") {
          handleHiddenAlert();
        }
      }
    },
    [handleHiddenAlert]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  if (!alert) return null;

  return (
    <ButtonGroup sx={{ mt: GlobalSizes.smallGap }} color="inherit" size="small" variant="contained" aria-label="Basic button group">
      <Tooltip title={alert.hidden ? "Unarchive" : "Archive"} placement="top">
        <Button
          color="inherit"
          onClick={handleHiddenAlert}
          aria-label="Archive Alert"
          startIcon={alert.hidden ? <ArchiveIcon /> : <ArchiveIcon color="disabled" />}
        >
          {alert.hidden ? "Archived" : "Archive"}
        </Button>
      </Tooltip>
      <Tooltip title={alert.flagged ? "Unflag" : "Flag"} placement="top">
        <Button
          color={alert.flagged ? "error" : "inherit"}
          onClick={() => {
            updateAlertRequest({ url: alert.url, flagged: !alert.flagged, flag_date: Date.now() });
            updateAlerts({ ...alert, flagged: !alert.flagged, flag_date: Date.now() });
            alert.flagged = !alert.flagged;
          }}
          aria-label="Flag Alert"
          startIcon={<FlagIcon color={!alert.flagged ? "disabled" : "action"} />}
        >
          {alert.flagged ? "Flagged" : "Flag"}
        </Button>
      </Tooltip>
      <Tooltip title={"View full investigation"} placement="top">
        <Button
          color={alert.ai_filter?.status === "success" ? "primary" : "inherit"}
          onClick={() => {
            setCurrentAlert(alert);
            nav(`/investigation?url=${encodeURIComponent(alert.url)}`);
            setSidebarAlert(undefined);
          }}
          aria-label="Investigation"
          startIcon={<TroubleshootIcon />}
        >
          {labels.investigation.title}
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
}
