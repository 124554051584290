import { MentionType, SeverityType } from "../state";
import { Box, Chip, Menu, MenuItem, Tooltip } from "@mui/material";
import { severity } from "../labels";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import { useWsContext } from "../ws-context";

function SeverityChip({ alert }: { alert?: MentionType }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSeverity, setSelectedSeverity] = useState<SeverityType>(alert?.manual_severity || alert?.severity || "medium");

  const { updateAlerts, emitNewData } = useWsContext();

  let icon;
  let tooltip = "Severity automatically set by the system";

  if (alert?.manual_severity) {
    icon = <CheckIcon />;
    tooltip = "Severity manually set by user to train the system";
  }

  const handleChipClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleSeverityChange = (severity: SeverityType) => {
    if (!alert) return;
    setSelectedSeverity(severity);
    updateAlerts({ ...alert, manual_severity: severity });
    emitNewData({ action: "updateAlert", mention: { url: alert.url, customer_id: alert.customer_id, manual_severity: severity } });
    setAnchorEl(null);
  };

  const displayedSeverity = selectedSeverity.toUpperCase();

  useEffect(() => {
    setSelectedSeverity(alert?.manual_severity || alert?.severity || "medium");
  }, [alert?.manual_severity, alert?.severity]);

  return (
    <Box>
      <Tooltip title={`${displayedSeverity} - ${tooltip}`}>
        <Chip
          icon={icon}
          size="small"
          label={displayedSeverity}
          sx={{
            backgroundColor: severity[displayedSeverity]?.color,
            color: "black",
            ":hover": { backgroundColor: severity[displayedSeverity]?.color, opacity: "0.8" },
          }}
          onClick={handleChipClick}
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {Object.keys(severity).map((key) => {
          const lowerKey = key.toLowerCase() as SeverityType;
          return (
            <MenuItem key={lowerKey} selected={lowerKey === selectedSeverity} onClick={() => handleSeverityChange(lowerKey)}>
              {key}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

export default SeverityChip;
