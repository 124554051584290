export const GlobalSizes = {
  title: "3rem",
  smallGap: 1,
  gap: 2,
  largeGap: 12,
  mediumGap: "20px",
  bigChipHeight: 35,
  smallSize: "30%",
  halfSize: "50%",
  midSize: "65%",
  largeSize: "80%",
  fullSize: "100%",
  none: "0px",
  flex: 1,
  buttonPadding: "10px",
  smallFontSize: 17,
  bigFontSize: "1.25rem",
  borderWidth: "1px",
  borderRadius: "3px",
  largeHeight: 300,
  chartHeight: 350,
  fullScreenHeight: "100vh",
  cards: { width: 1000, height: 400 },
  autoMargin: "auto",
};

export const InvestigationImageSizes = {
  width: "600px",
  height: "300px",
};

export const ManuAlLinksFieldsSizes = {
  gridSpan: 8,
};

export const TweetCardSizes = {
  size: { width: "550px", height: "auto" },
  borderRadius: 2,
  offscreenPosition: "-9999px",
  closeButtonRight: -1,
};

export const TweetCardContentSizes = {
  tweetIconSize: { width: 14, height: 14 },
  SvgSizes: {
    replyIconDimension: "501.133px",
  },
};

export const SidePanelSizes = {
  width: "150px",
};

export const ActionSizes = {
  workerWrapperWidth: "400px",
};

export const InvestigationSizes = {
  postsDropDownMaxWidth: "900px",
  mtTypography: 8,
  twitterEmbedDivWidth: "36rem",
};

export const LoginPageSizes = {
  logoSize: "100",
};

export const FlaggingSizes = {
  mtCaption: 0.2,
  paperWidth: 800,
};

export const TeamSizes = {
  borderRadius: "5px",
  selectHeight: "50px",
};

export const AssetsSizes = {
  buttonMaxWidth: "120px",
  cardMaxWidth: "350px",
};

export const avatarSize = {
  smallerAvatar: { width: 36, height: 36 },
};

export const dialogListSizes = {
  dialogListPadding: { pt: 1 },
};
export const pagesContainerMargins = {
  marginTop: GlobalSizes.mediumGap,
  marginLeft: 0,
  marginRight: 0,
  marginBottom: "40px",
  paddingBottom: "20px",
};
