import { Stack, Tooltip, Typography } from "@mui/material";
import { SOCIAL_INFO } from "../labels";

export default function CollectionCoverage({ coverageSources }: { coverageSources: string[] | undefined }) {
  const SourceListItem = ({ source }: { source: string }) => {
    const { name, icon: SourceIcon } = SOCIAL_INFO[source];
    return (
      <Tooltip title={name}>
        <SourceIcon fontSize="small" color="disabled" />
      </Tooltip>
    );
  };
  return (
    <Stack
      alignSelf="flex-end"
      direction="row"
      alignItems="center"
      spacing={1}
    >
      <Typography variant="caption" color="text.disabled" >Coverage: {!coverageSources?.length && `collection disabled`}</Typography>
      {coverageSources?.map((source) => <SourceListItem source={source} />)}
    </Stack>
  );
};
